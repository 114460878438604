<template>
  <v-container fluid>
    <head-title text="Anulaciones" :to="{ name: 'HomeDtes' }"></head-title>
    <v-row justify="space-between">
      <v-col cols="12" md="2">
        <v-btn color="success" @click="annulmentVisible = true">
          NUEVA ANULACION
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn
          class="mx-1"
          fab
          dark
          small
          color="blue darken-3"
          @click="getList()"
        >
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      disable-pagination
      disable-sort
      class="elevation-1"
    >
      <template v-slot:[`item.fecha_emision_dte_anulacion`]="{ item }">{{
        item.fecha_emision_dte_anulacion | date
      }}</template>
      <template v-slot:[`item.fecha_anulacion`]="{ item }">{{
        item.fecha_anulacion | date
      }}</template>
      <template v-slot:[`item.registrado`]="{ item }">
        <v-icon v-if="item.registrado == true" small color="green darken-2">
          mdi-check-outline
        </v-icon>
        <v-icon v-else small color="red darken-2">
          mdi-close-outline
        </v-icon>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :total-visible="10"
        :length="totalPages"
        :disabled="loading"
        v-show="totalPages > 0"
      ></v-pagination>
    </div>
    <annulment-dialog
      :visible="annulmentVisible"
      @close="annulmentVisible = false"
      @updated="getList()"
    ></annulment-dialog>
  </v-container>
</template>
<script>
import requests from "@/services/requests";

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    AnnulmentDialog: () => import("@/components/dtes/Annulment")
  },
  data: () => ({
    annulmentVisible: false,
    currentPage: 1,
    totalPages: 0,
    loading: true,
    items: [],
    headers: [
      {
        text: "Correlativo Interno DTE",
        value: "correrativo_interno_dte_anulacion"
      },
      { text: "Autorización DTE", value: "autorizacion_dte_anulacion" },
      { text: "Fecha emisión DTE", value: "fecha_emision_dte_anulacion" },
      { text: "Fecha Anulación", value: "fecha_anulacion" },
      { text: "Autorizado por", value: "created_by.username" },
      { text: "Registrado por", value: "register_by.username" },
      { text: "Registrado", value: "registrado", align: "center" }
    ]
  }),
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      requests
        .get("/api/annulment-dte/?page=" + this.currentPage)
        .then(response => {
          if (response.status == 200) {
            this.items = response.data.results;
            this.totalPages = response.data.total_pages;
          }
          this.loading = false;
        });
    }
  },
  watch: {
    currentPage: function(val) {
      console.log(val);
      this.getList();
    }
  }
};
</script>
